.TrainingCourse {
  padding: 15px;
}

/* .javaCourse {
  background-color: #8e2ad90d;
} */
@media screen and (max-width:767px) {
  .banner-content h1 {
    font-size: 1.5rem !important;
  }

  .banner-content h2,
  .banner-content h4 {
    font-size: 1rem !important;
  }

  .banner-content h3 {
    font-size: 0.9rem !important;
  }

  .banner-content h5 {
    font-size: 0.7rem !important;
  }
}


.javaCourse h1 {
  color: purple
}

.javaCourse h2 {
  text-align: center;
  font-size: 28px;
  color: #fff;
  background-color: skyblue;
  margin-bottom: 20px;
  animation: fadeInUp 1s ease;
  text-transform: uppercase;
  padding: 5px;
  font-weight: bold;
  letter-spacing: 2px;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 1);
}

.javaCourse h3 {
  color: #1596c0;
}

.javaCourse h4 {
  color: darkslateblue;
}

.javaCourse h5 {
  margin: 15px;
  font-style: italic;
  color: mediumvioletred;
  font-size: 14px;
  padding-bottom: 8px;
}

.javaCourse a {
  color: #fff;
  padding: 5px 8px;
  margin: 15px;
  text-decoration: none;
  border-radius: 5px;
  background-color: purple;
  box-shadow: 1px 1px 1px palevioletred;
}

/* .dataCourse{
    background-color: #F6FFEE;
} */
.dataCourse h1 {
  color: darkorange;
}

.dataCourse h2 {
  text-align: center;
  font-size: 28px;
  color: #fff;
  padding: 5px;
  background: radial-gradient(circle at 50.3% 44.5%, rgb(111 123 137) 0%, rgb(64 80 96 / 55%) 100.2%);
  margin-bottom: 20px;
  animation: fadeInUp 1s ease;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 2px;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 1);
}

.dataCourse h3 {
  color: #11bdf6;
  /* Add a subtle text shadow */
}

.dataCourse h4 {
  color: floralwhite;
}

.dataCourse h5 {
  margin: 15px;
  font-style: italic;
  color: azure;
  font-size: 14px;
  padding-bottom: 8px;
}

.dataCourse a {
  color: #F6FFEE;
  padding: 5px 8px;
  margin: 15px;
  text-decoration: none;
  border-radius: 5px;
  background-color: #e79705;
  box-shadow: 1px 1px 1px #000;
}

/* .uiCourse {
  background-color: #faf6f2;
} */

.uiCourse h1 {
  color: cadetblue;
}

.uiCourse h2 {
  text-align: center;
  font-size: 28px;
  color: #fff;
  background: linear-gradient(91.9deg, #5f9ea0a8 0.1%, rgb(255, 237, 237) 70.9%, cadetblue 186.2%);
  margin-bottom: 20px;
  padding: 5px;
  animation: fadeInUp 1s ease;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 2px;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 1);
}

.uiCourse h3 {
  color: #1596c0;
}

.uiCourse h4 {
  color: darkslateblue;
}

.uiCourse h5 {
  margin: 15px;
  font-style: italic;
  color: mediumvioletred;
  font-size: 14px;
  padding-bottom: 8px;
}

.uiCourse a {
  color: #FAF6F2;
  padding: 5px 8px;
  margin: 15px;
  text-decoration: none;
  border-radius: 5px;
  background-color: cadetblue;
  box-shadow: 1px 1px 1px #000;
}