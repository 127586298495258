/* CourseOfferings.css */

.course {
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  transition: transform 0.2s;
  cursor: pointer;
}

.course:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.course h5 {
  margin: 20px 0 10px;
  color: #333;
  text-align: center;
}

.course h6 {
  background-color: rgb(94 182 40 / 57%);
  text-align: center;
  padding: 15px;
}

.course p {
  font-size: 1rem;
  color: #666;
  margin: 10px 0;
}

.course button {
  background: #fafafa;
  border: 1px solid #e0e0e0;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  color: #000;
}

.course button:hover {
  background-color: #1596c0;
  opacity: 0.8;
}


.original-price {
  text-decoration: line-through;
  margin-left: 5px;
  color: #999;
}


.ct_cf {
  color: red;
  margin-right: 7px;
}