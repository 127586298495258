/* Default styles for both mobile and desktop */
.header {
  background-color: #333;
  color: #fff;
  padding: 20px 0;
  transition: background-color 0.3s ease;
}

.logo img {
  max-width: 100%;
  border-radius: 12px;
}

.btn.btn-danger a {
  text-decoration: none;
  color: #fefefe;
}

.top-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.contact-info span {
  margin-right: 15px;
  margin-left: 15px;
}

/* Styles for navigation menu */
.navigation ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.navigation ul li {
  margin: 0 10px;
  display: inline;
}

.navigation ul li a {
  text-decoration: none;
  color: #fff;
  transition: color 0.3s ease;

  &:hover {
    color: #00f;
  }
}

/* Hide submenu by default */
.navigation ul li ul.submenu {
  position: absolute;
  z-index: 999999;
  padding: 20px;
  background: #181818;
  display: none;
}

.navigation ul li ul.submenu li {
  list-style-type: circle;
  padding: 5px;
  display: block;
}

/* Display submenu when parent list item is hovered */
.navigation ul li:hover ul.submenu {
  display: block;
}

/* Mobile menu styles */
.mobile-menu-toggle {
  color: #007bb5;
  display: none;
  background: none;
  border: none;
  cursor: pointer;

  i {
    font-size: 24px;
  }
}

.navigation.open {
  display: block;
}

/* Style for the WhatsApp chat link in the popup */
.whatsapp-link {
  display: block;
  background-color: #25d366;
  /* WhatsApp green */
  color: white;
  text-align: center;
  padding: 10px 0;
  border: none;
  border-radius: 5px;
  text-decoration: none;
  cursor: pointer;
}


.rounded-social-buttons .social-button {
  display: inline-block;
  position: relative;
  cursor: pointer;
  width: 40px;
  height: 40px;
  border: 0.125rem solid transparent;
  padding: 0;
  text-decoration: none;
  text-align: center;
  color: #fefefe;
  font-size: 20px;
  font-weight: normal;
  line-height: 1.9em;
  border-radius: 1.6875rem;
  transition: all 0.5s ease;
  margin-right: 0.25rem;
  margin-bottom: 0.25rem;
}

.rounded-social-buttons .social-button:hover,
.rounded-social-buttons .social-button:focus {
  -webkit-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  transform: rotate(360deg);
}

.rounded-social-buttons .fa-twitter,
.fa-facebook-f,
.fa-linkedin,
.fa-youtube,
.fa-instagram,
.whatsapp {
  /* font-size: 20px;*/
  line-height: 1.7;
}

.rounded-social-buttons .social-button.whatsapp {
  background: #1ecc07;
}

.rounded-social-buttons .social-button.whatsapp:hover,
.rounded-social-buttons .social-button.whatsapp:focus {
  color: #1ecc07;
  background: #fefefe;
  border-color: #1ecc07;
}

.rounded-social-buttons .social-button.facebook {
  background: #3b5998;
}

.rounded-social-buttons .social-button.facebook:hover,
.rounded-social-buttons .social-button.facebook:focus {
  color: #3b5998;
  background: #fefefe;
  border-color: #3b5998;
}

.rounded-social-buttons .social-button.twitter {
  background: #55acee;
}

.rounded-social-buttons .social-button.twitter:hover,
.rounded-social-buttons .social-button.twitter:focus {
  color: #55acee;
  background: #fefefe;
  border-color: #55acee;
}

.rounded-social-buttons .social-button.linkedin {
  background: #007bb5;
}

.rounded-social-buttons .social-button.linkedin:hover,
.rounded-social-buttons .social-button.linkedin:focus {
  color: #007bb5;
  background: #fefefe;
  border-color: #007bb5;
}

.rounded-social-buttons .social-button.youtube {
  background: #bb0000;
}

.rounded-social-buttons .social-button.youtube:hover,
.rounded-social-buttons .social-button.youtube:focus {
  color: #bb0000;
  background: #fefefe;
  border-color: #bb0000;
}

.rounded-social-buttons .social-button.instagram {
  background: #D6274D;
}

.rounded-social-buttons .social-button.telegram {
  background: #0088CC;
}

.rounded-social-buttons .social-button.instagram:hover,
.rounded-social-buttons .social-button.instagram:focus {
  color: #D6274D;
  background: #fefefe;
  border-color: #D6274D;
}


.rounded-social-buttons .social-button.telegram:hover,
.rounded-social-buttons .social-button.telegram:focus {
  color: #0088CC;
  background: #fefefe;
  border-color: #0088CC;
}

.whatsup {
  background: green;
}

.rounded-social-buttons .social-button.whatsup:hover,
.rounded-social-buttons .social-button.whatsup:focus {
  color: green;
  background: #fefefe;
  border-color: green;
}



/*--

/* Responsive styles */
@media (max-width: 767px) {

  .desktop {
    display: none;
  }

  .rounded-social-buttons {
    display: flex;
    align-items: center;
  }

  .rounded-social-buttons .social-button {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    margin: 0px 6px 0px 0px;
  }

  .mobile {
    display: block;
  }

  .navigation ul li {
    display: block;
    position: relative;
    padding: 10px 5px;
  }

  .course-enquiry {
    display: none;
  }

  .navigation.close {
    display: none;
  }

  .navigation.open {
    display: block;
  }

  .mobile-menu-toggle {
    display: block;
    font-size: 50px;
  }
}

/* Desktop-specific styles */
@media (min-width: 769px) {
  .mobile {
    display: none;
  }

  .desktop {
    display: block;
  }

  .rounded-social-buttons .social-button {
    width: 30px;
    height: 30px;
    line-height: 25px;
    font-size: 15px;
  }
}