/* Example CSS for chatbot component */
.chatbot-container {
  position: relative;
}

.chatbot-icon {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  right: -85px;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  background-color: #25d366;
  color: white;
  padding: 8px 12px;
  rotate: -90deg;
  border: none;
  display: flex;
  align-items: center;
  cursor: pointer;

  .fa {
    margin-right: 10px;
  }
}

.chatHeader {
  background: currentColor;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.chatbot-popup {
  z-index: 100;
  display: none;
  position: fixed;
  bottom: 100px;
  right: 38px;
  width: 300px;
  background-color: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}

.chatbot-popup.open {
  display: block;
}

.close-button {
  color: #11bdf6;
  border-radius: 5px;
  border: none;
  cursor: pointer;
}

.whatsapp-link {
  display: block;
  background-color: #1596c0;
  color: white;
  text-align: center;
  padding: 6px 0;
  border: none;
  border-radius: 5px;
  text-decoration: none;
  cursor: pointer;
}

.great {
  color: mediumvioletred;
  padding: 15px 80px 15px 15px;
  border: 1px solid #11bdf6;
  border-radius: 5px;
}