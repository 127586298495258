/* styles.css */

/* Style for the slide content */
.slide-content {
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: background-color 0.13s, transform 0.13s;

}

.carousel-indicators {
  margin-bottom: 0px !important;
}

.carousel {
  padding: 0px;
  z-index: 0;
}